<template lang="pug">
form-layout(title="ᲞᲐᲠᲝᲚᲘᲡ ᲐᲦᲓᲒᲔᲜᲐ")
	template(#right)
		.recover-password
			.recover-password-first(v-if="recoveryPasswordStep === 1" )
				DefaultInput(type="tel" text="ტელეფონი" v-model:value="phone_number" :errors="(firstStepError && firstStepError.length) && firstStepError")
				.recover-password-first-item
					DefaultButton(text="ᲙᲝᲓᲘᲡ ᲛᲘᲦᲔᲑᲐ" @clicked="recoverySendSms(false)")
			VerificationCodeForm(
				v-else-if="recoveryPasswordStep === 2"
				@validateCode="validateCode"
				@reSent="recoverySendSms(true)"
				@changeShowLoader="value => this.showLoader = value"
				title="შეიყვანეთ ვერიფიკაციის კოდი"
				:animation="animation"
				:showLoader="showLoader"
			)
			NewPasswordForm(
				v-else-if="recoveryPasswordStep === 3"
				@newPassword="value => this.new_password = value"
				@repeatNewPassword="value => this.repeat_new_password = value"
				@send="sendPasswords"
			)
			SuccessNotification(v-else)
				router-link(:to="{name:'Login'}") შესვლა
</template>

<script>
import {ajax, apiUrls} from "../api/urls";
import FormLayout from "../components/layout/FormLayout";
import DefaultInput from "../components/layout/DefaultInput";
import DefaultButton from "../components/layout/DefaultButton";
import SuccessNotification from "../components/forms/SuccessNotification";
import VerificationCodeForm from "../components/forms/VerificationCodeForm";
import NewPasswordForm from "../components/forms/NewPasswordForm";

export default {
	name: "RecoverPassword",
	components: {
		NewPasswordForm,
		VerificationCodeForm,
		DefaultButton,
		FormLayout,
		DefaultInput,
		SuccessNotification
	},
	data() {
		return {
			recoveryPasswordStep: 1,
			phone_number: null,
			firstStepError: [],
			animation: true,
			showLoader: false,
			new_password: null,
			repeat_new_password: null,
			hash_key: null,
			token: null
		}
	},
	watch: {
		recoveryPasswordStep(newVal) {
			if (newVal === 4) {
				setTimeout(() => {
					this.$router.push('/login')
				}, 5000)
			}
		}
	},
	methods: {
		recoverySendSms(isReSend) {
			if (this.phone_number && this.phone_number.length) {
				ajax.post(apiUrls.recoverySendSms(this.phone_number)).then((res) => {
					if (!isReSend) {
						this.recoveryPasswordStep++
					}
					this.firstStepError = []
					this.hash_key = res.data.hash
				}).catch(() => {
				})
			} else {
				this.firstStepError.push('ტელეფონის ნომრის შეყვანა აუცილებელია')
			}
		},
		validateCode(code) {
			ajax.post(apiUrls.recoverySendCode(this.phone_number), {code: code, hash_key: this.hash_key}).then(res => {
				this.recoveryPasswordStep++
				this.token = res.data.token
			}).catch(() => {
			})
		},
		sendPasswords() {
			ajax.put(apiUrls.changePassword(this.phone_number, this.token), {
				password: this.new_password,
				password2: this.repeat_new_password
			}).then(() => {
				this.recoveryPasswordStep++
			}).catch(() => {
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.recover-password {
	margin: 33px 0 0;
	display: flex;
	place-items: center;
	flex-direction: column;

	.recover-password-first-item {
		margin-top: 20px;
	}

	a {
		cursor: pointer;
		margin-top: 12px;
		color: black;
		font-size: 12px;
		font-weight: bold;
		text-decoration: none;
		text-align: center;
		width: 100%;
	}

	:deep(.success) {
		margin: 0;
	}

	:deep(.verification-code-form) {
		> p {
			margin-top: 0;
		}
	}
}
</style>
