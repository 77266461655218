<template lang="pug">
.new-password-form
    DefaultInput(type="password" v-model:value="newPassword" text="ახალი პაროლი" @enterClick="$emit('send')")
    DefaultInput(type="password" v-model:value="repeatNewPassword" text="გაიმეორე პაროლი" @enterClick="$emit('send')")
    DefaultButton(text="ᲒᲐᲒᲖᲐᲕᲜᲐ" @clicked="$emit('send')")
</template>

<script>
import DefaultButton from "@/components/layout/DefaultButton";
import DefaultInput from "@/components/layout/DefaultInput";
export default {
    name: "NewPasswordForm",
    emits: ['newPassword', 'repeatNewPassword', 'send'],
    components: {
        DefaultInput,
        DefaultButton
    },
    computed: {
        newPassword: {
            get() {
                return null
            },
            set(value) {
                this.$emit('newPassword', value)
            }
        },
        repeatNewPassword: {
            get() {
                return null
            },
            set(value) {
                this.$emit('repeatNewPassword', value)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.new-password-form {
    :deep(.default-input) {
        margin-bottom: 10px;
    }

    :deep(.btn) {
        margin-top: 20px;
    }
}
</style>
