<template lang="pug">
.success
    img(src='@/assets/images/check-circle.svg')
    span თქვენი პაროლი წარმატებით შეიცვალა
slot
</template>

<script>
export default {
    name: "SuccessNotification"
}
</script>

<style lang="scss" scoped>
.success {
    margin-top: 24px;

    img {
        margin-right: 10px;
    }

    span {
        color: #434242;
        font-size: 12px;
    }
}
</style>
